const reverseObj = (input) => {
    const output = {}
    for (let [k, v] of Object.entries(input) ) {
        output[v] = k
    }
    return output
}
export {
    reverseObj
}
