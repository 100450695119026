import {RestApi} from "../utils/rest-api"
import {API_URLS} from "../resources/urls"
import {Tenant} from "../models/Tenant";

export default class TenantManager {
    constructor() {
        this.tenantApi = new RestApi(API_URLS.TENANT, Tenant)
        this.switchableTenants = []
    }

    async loadAll() {
        this.switchableTenants = await this.tenantApi.list()
    }

    get all() {
        return this.switchableTenants
    }

    get allSelections() {
        return this.all.map(tenant => ({
            text: tenant.name,
            value: tenant.identifier
        }))
    }

    get switchable() {
        return this.switchableTenants.length >= 1
    }
}
