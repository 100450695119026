import { reverseObj } from "../../_common/utils/reverse-obj"

const FieldTypes = Object.freeze({
    TEXT: "1",
    // NUMBER: "2",
    // PASSWORD: "3",
    SELECT: "4",
    // RADIO: "5",
    // CHECKBOX: "6",
    // TEXTAREA: "7"
})

const ReversedFieldTypes = Object.freeze(reverseObj(FieldTypes))

const PlanStatus = Object.freeze({
    OPEN: "1",
    PREPARING: "2"
})

const PlanExpireTypes = Object.freeze({
    TIME: "1",
    DATETIME: "2",
})

const ExportTypes = Object.freeze({
    EXCEL: "1",
    CSV: "2"
})

const SubscriberRegisteredTypes = Object.freeze({
    NORMAL: "1",
    PREREGISTERED_UNSIGNED: "2",
    PREREGISTERED_SIGNED: "3"
})

const PreregisteredSubscriberExpireTypes = Object.freeze({
    FOLLOW_PLAN: "1",
    SPECIFIC_DATETIME: "2",
    TIME_FROM_CREATED: "3",
    TIME_FROM_SIGNED: "4"
})

export {
    FieldTypes,
    ReversedFieldTypes,
    PlanExpireTypes,
    PlanStatus,
    ExportTypes,
    SubscriberRegisteredTypes,
    PreregisteredSubscriberExpireTypes
}
