<template>
    <v-navigation-drawer v-if="controller.loggedIn"
        v-model="menuDisplay" app clipped temporary
    >
        <template v-slot:prepend>
            <v-toolbar flat dense></v-toolbar>
            <v-divider></v-divider>
        </template>
        <v-list>
            <v-list-item :href="controller.consoleUrl">
                <v-list-item-icon>
                    <v-icon>mdi-console</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    {{ $t('nav.console') }}
                </v-list-item-content>
            </v-list-item>
            <v-list-item :href="controller.mapUrl">
                <v-list-item-icon>
                    <v-icon>mdi-google-maps</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    {{ $t('nav.map') }}
                </v-list-item-content>
            </v-list-item>
            <v-list-item :href="controller.customizationUrl">
                <v-list-item-icon>
                    <v-icon>mdi-palette</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    {{ $t('nav.customization') }}
                </v-list-item-content>
            </v-list-item>
            <v-list-item :href="controller.speechUrl">
                <v-list-item-icon>
                    <v-icon>mdi-text-to-speech</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    {{ $t('nav.speech') }}
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
    export default {
        name: "Menu",
        props: ['controller', 'isPC'],
        data () {
            return {
                menuDisplay: false,
            }
        },
        methods: {
            toggle() {
                this.menuDisplay = !this.menuDisplay
            }
        }
    }
</script>

<style scoped>

</style>