import Vue from 'vue'
import { extend, ValidationProvider, ValidationObserver } from "vee-validate"
import { between } from "vee-validate/dist/rules"
import i18n from "./i18n"

extend('required', {
    validate: value => {
        if (value && value.trim() && value.trim().length > 0) {
            return true
        }
        return i18n.t("general.required")
    },
    computesRequired: true
})

extend('positive-integer', {
    validate: value => {
        try {
            if (value && /^[0-9]+$/.test(value.trim())) {
                const parsedValue = Number.parseInt(value.trim(), 10)
                if (parsedValue >= 0) {
                    return true
                }
            }
        } catch (e) {
            return i18n.t('general.positiveInteger')
        }
        return i18n.t('general.positiveInteger')
    },
    computesRequired: true
})

extend('identifier', {
    validate: value => {
        const result = /^[a-zA-Z0-9]+([a-zA-Z0-9-_]*[a-zA-Z0-9]+|)$/.test(value)
        if (result) {
            return result
        } else {
            return i18n.t('field.invalidIdentifier')
        }
    },
    computesRequired: true
})

extend('between', {
    ...between,
    message: i18n.t('general.valueShouldBetween')
})

Vue.component('validation-provider', ValidationProvider)
Vue.component('validation-observer', ValidationObserver)
