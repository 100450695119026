export default class MinuteFormatter {

    static minuteToObj(inputStr) {
        const input = parseInt(inputStr)
        const dayMod = input % (24 * 60)
        const day = Math.floor(input / (24 * 60))
        const minute = dayMod % (60)
        const hour = Math.floor(dayMod / 60)

        return {
            day: day.toString(),
            hour: hour.toString(),
            minute: minute.toString()
        }
    }

    static objToMinute(obj) {
        try {
            return (parseInt(obj.minute) + (60 * parseInt(obj.hour)) + (24 * 60 * parseInt(obj.day))).toString()
        } catch (e) {
            return (10080).toString
        }
    }
}
