import {PlanExpireTypes, PlanStatus} from "../utils/const";
import MinuteFormatter from "../utils/minute-formatter";

class Plan {
    constructor(id=null, name="", pid="", status=PlanStatus.PREPARING, updatedAt=null, surveyId=null, surveyName="", expireSetting=null, origin=null) {
        this._id = id
        this.name = name
        this._pid = pid
        this._status = status
        this._updatedAt = updatedAt
        this.surveyId = surveyId
        this._surveyName = surveyName
        this.expireSetting = null
        if (expireSetting) {
            this.expireSetting = PlanExpireSetting.fromDate(expireSetting)
        } else {
            this.expireSetting = new PlanExpireSetting()
        }
        this._origin = origin
    }

    _toggleStatus() {
        this._status =  this._status === PlanStatus.PREPARING ? PlanStatus.OPEN : PlanStatus.PREPARING
    }

    get id() {
        return this._id
    }

    get pid() {
        return this._pid
    }

    get opening() {
        return this._status === PlanStatus.OPEN
    }

    set opening(_) {
        this._toggleStatus()
    }

    get surveyName() {
        return this._surveyName
    }

    get updatedAt() {
        return this._updatedAt
    }

    static fromData(data) {
        return new Plan(
            data.id || null,
            data.name || "",
            data.pid || null,
            data.status || PlanStatus.PREPARING,
            data.updated_at || null,
            data.survey || null,
            data.survey_name || "",
            data.plan_expire_setting || null,
            data || null
        )
    }

    toData() {
        return {
            id: this._id,
            name: this.name,
            status: this._status,
            survey: this.surveyId,
            plan_expire_setting: this.expireSetting.toData()
        }
    }

    reset() {
        if (this._origin) {
            this.name = this._origin.name || ""
            this.surveyId = this._origin.survey
            this.expireSetting.reset()
        }
    }
}

class PlanExpireSetting {
    constructor(expireType=PlanExpireTypes.TIME, expireDay="7", expireDatetime="", expireMinute="10080", origin=null) {
        this.expireType = expireType
        this.expireDay = expireDay
        const splits = expireDatetime.split(" ")
        this.expireDate = splits[0]
        this.expireTime = splits[1]
        this.expireDatetime = expireDatetime
        this.expireMinute = expireMinute
        this.displayExpireMinute = MinuteFormatter.minuteToObj(this.expireMinute)
        this._origin = origin
    }

    static fromDate(data) {
        return new PlanExpireSetting(
            data.expire_type || PlanExpireTypes.TIME,
            data.expire_day.toString() || "7",
            data.expire_datetime || "",
            data.expire_minute.toString() || "10080",
            data || null
        )
    }

    toData() {
        return {
            expire_type: this.expireType,
            expire_day: "7",
            expire_datetime: this.expireDate.length > 0 && this.expireTime.length > 0 ? `${this.expireDate} ${this.expireTime}` : null,
            expire_minute: this.expireType === PlanExpireTypes.TIME ? MinuteFormatter.objToMinute(this.displayExpireMinute) : "10080",
        }
    }

    reset() {
        if (this._origin) {
            this.expireType = this._origin.expire_type || PlanExpireTypes.TIME
            this.expireDay = "7"
            this.expireDatetime = this._origin.expire_datetime || ""
            this.expireMinute = this._origin.expire_minute.toString() || "10080"
            this.displayExpireMinute = MinuteFormatter.minuteToObj(this.expireMinute)
        }
    }
}

export {
    Plan
}
