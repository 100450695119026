import TenantManager from "./tenant-manager"
import {VIEW_URLS} from "../resources/urls";

export default class NavController {

    constructor(context) {
        this._loggedIn = false
        if (context) {
            this._loggedIn = true
            this.tenantName = context.tenantName
            this.tenantIdentifier = context.tenantIdentifier
            this.tenantManager = new TenantManager()
            this.ready()
        }
    }

    ready() {
        this.tenantManager.loadAll()
    }

    get loggedIn() {
        return !!this._loggedIn
    }

    get switchable() {
        return this.tenantManager && this.tenantManager.switchable
    }
    get allTenants() {
        return this.tenantManager.all
    }
    get tenantSwitchUrl() {
        return VIEW_URLS.TENANT_SWITCH
    }
    get consoleUrl() {
        return VIEW_URLS.CONSOLE
    }
    get tenantUrl() {
        return VIEW_URLS.TENANT
    }
    get logoutUrl() {
        return VIEW_URLS.LOGOUT
    }
    get appUrl() {
        return VIEW_URLS.APP + this.tenantIdentifier
    }
    get mapUrl() {
        return VIEW_URLS.MAP
    }
    get customizationUrl() {
        return VIEW_URLS.CUSTOMIZATION
    }
    get speechUrl() {
        return VIEW_URLS.SPEECH
    }
}
