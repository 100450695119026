<template>
    <v-app>
        <mpy-menu ref="mpyMenu" :isPC="isPC" :controller="controller"></mpy-menu>
        <mpy-nav-bar v-resize="resized" :controller="controller" :isPC="isPC" @toggle-menu="$refs.mpyMenu.toggle()"></mpy-nav-bar>
        <v-content>
            <slot></slot>
        </v-content>
        <v-footer app color="white">
            <v-row justify="center" dense>
                <span style="font-size: 12px;color: rgba(0, 0, 0, 0.54);">
                    <v-icon x-small>mdi-copyright</v-icon> 2020 ObotAI
                </span>
            </v-row>
        </v-footer>
    </v-app>
</template>

<script>
    import NavController from "./controllers/nav"
    import Nav from "./components/Nav"
    import Menu from "./components/Menu"
    export default {
        name: "Base",
        components: {
            'mpy-nav-bar': Nav,
            'mpy-menu': Menu
        },
        data() {
            return {
                controller: new NavController(window.appNavContext),
                isPC: false
            }
        },
        methods: {
            resized() {
                this.isPC = window.innerWidth >= 1024
            }
        }
    }
</script>
