import {UrlBuilder} from "../utils/url-builder";

const TENANT_API_PREFIX = "/api/tenant"
const CONSOLE_PREFIX = ""
const TENANT_PREFIX = "/tenant"
const ACCOUNTS_PREFIX = "/account"
const MAP_PREFIX = "/map"
const CUSTOMIZATION_PREFIX = "/customization"
const SPEECH_PREFIX = "/speech"

const API_URL = {
    TENANT: `${TENANT_API_PREFIX}/`,
}

const VIEW_URL = {
    TENANT_SWITCH: `${TENANT_PREFIX}/switch/`,
    LOGOUT: `${ACCOUNTS_PREFIX}/logout/`,
    CONSOLE: `${CONSOLE_PREFIX}/`,
    TENANT: `${TENANT_PREFIX}/`,
    APP: '/t/',
    MAP: `${MAP_PREFIX}/`,
    CUSTOMIZATION: `${CUSTOMIZATION_PREFIX}/`,
    SPEECH: `${SPEECH_PREFIX}/`
}

const API_URLS = new UrlBuilder(API_URL).build()

const VIEW_URLS = new UrlBuilder(VIEW_URL).build()

export {
    API_URLS,
    VIEW_URLS
}
