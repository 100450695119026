class Tenant {

    constructor(identifier, name) {
        this.identifier = identifier
        this.name = name
    }

    static fromData(data) {
        return new Tenant(data.identifier, data.name)
    }

}

export {
    Tenant
}