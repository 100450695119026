<template>
    <v-app-bar class="px-0" app dense dark color="primary" clipped-left style="z-index: 8;">
        <v-btn class="px-0" icon @click="$emit('toggle-menu')" v-if="controller.loggedIn">
          <v-icon>mdi-menu</v-icon>
        </v-btn>
        <v-toolbar-title class="pl-0"><a :href="controller.consoleUrl" style="color: white;text-decoration: none;">{{ $t('general.logo') }}</a></v-toolbar-title>
        <v-menu top v-if="controller.loggedIn && controller.allTenants.length > 0">
            <template v-slot:activator="{ on }">
                <v-btn v-on="on" outlined class="ml-2" small>
                    {{ controller.tenantName }}
                    <v-icon x-small right>
                        mdi-chevron-down
                    </v-icon>
                </v-btn>
            </template>
            <v-list>
                <template v-for="(tenant, index) in controller.allTenants">
                    <v-list-item :key="index" @click="doSwitchTenant(tenant.identifier)">
                        <v-list-item-title>{{ tenant.name }}</v-list-item-title>
                    </v-list-item>
                </template>
            </v-list>
        </v-menu>
        <v-spacer></v-spacer>
        <v-menu top>
            <template v-slot:activator="{ on }">
                <v-btn icon v-on="on" v-if="controller.loggedIn">
                  <v-icon>mdi-cogs</v-icon>
                </v-btn>
            </template>
            <v-list>
                <v-list-item :href="controller.tenantUrl">
                <v-list-item-title>{{ $t('general.settings') }}</v-list-item-title>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item :href="controller.logoutUrl">
                <v-list-item-title>{{ $t('general.logout') }}</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
    </v-app-bar>
</template>

<script>
    export default {
        name: "Nav",
        props: ['controller', 'isPC'],
        methods: {
            doSwitchTenant(identifier) {
                window.location.href = `${this.controller.tenantSwitchUrl}?identifier=${identifier}&next=${window.location.pathname}`
            }
        }
    }
</script>

<style scoped>

</style>